import { Controller } from "@hotwired/stimulus"

export default class CountdownController extends Controller {
  static targets = ["days", "hours", "minutes", "seconds"]
  static targetDate = Date.parse("2023-09-25T14:00:00.000Z")

  onTick() {
    const diff = CountdownController.targetDate - Date.now()
    this.daysTarget.innerText = Math.floor(diff / 1000 / 60 / 60 / 24)
    this.hoursTarget.innerText = Math.floor(diff / 1000 / 60 / 60) % 24
    this.minutesTarget.innerText = String(Math.floor(diff / 1000 / 60) % 60).padStart(
      2,
      "0",
    )
    this.secondsTarget.innerText = String(Math.floor(diff / 1000) % 60).padStart(2, "0")
  }

  connect() {
    setInterval(this.onTick.bind(this), 1000)
    this.onTick()
  }
}
