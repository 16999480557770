import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  scrollListener
  timer

  onScroll() {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.element.style.top = `${window.scrollY + 50}px`
    }, 0)
  }

  connect() {
    this.scrollListener = this.onScroll.bind(this)
    document.addEventListener("scroll", this.scrollListener)
  }

  disconnect() {
    document.removeEventListener("scroll", this.scrollListener)
  }
}
