console.log('Vite ⚡️ Ruby')
import { Application } from "@hotwired/stimulus"
import MenuController from '~/controllers/menu_float_controller'
import ContactController from '~/controllers/contact_controller'
import CountdownController from '~/controllers/countdown_controller'

window.Stimulus = Application.start()
Stimulus.register("menu-float", MenuController)
Stimulus.register("contact", ContactController)
Stimulus.register("countdown", CountdownController)

function menuAnimation() {
  window.scrollTo(0, 0)
  document.body.classList.toggle('menuopen')
}
