import { Controller } from "@hotwired/stimulus"

export default class ContactController extends Controller {
  static targets = ["thankyou", "errorPlaceholder"]

  connect() {
    const form = this.element.querySelector("form")
    this.element.addEventListener("submit", async (event) => {
      event.preventDefault()

      try {
        const result = await fetch("/", {
          method: "POST",
          body: new URLSearchParams([...new FormData(event.target)]),
        })
        if (!result.ok) throw new Error(await result.text())
        this.thankyouTarget.style.display = "block"
        form.style.display = "none"
      } catch (e) {
        this.errorPlaceholderTarget.style.display = "block"
        console.error(e)
      }
    })
  }
}
